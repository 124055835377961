@import '../_mixins.scss';

.app__wrapper {
	max-width: 750px;
	width: 100%;
	margin: 0 auto;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding-bottom: 70px;
	// margin-bottom: 100px;
	//min-height: 100vh;
}

//The scroll bar in T & C will become always tangible
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.appHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
}
.content__wrapper {
	background-color: var(--bg-2);
	padding: 2rem;
	border-radius: 12px;
	@include smallDeviceSize {
		padding: 1.5rem;
	}
}
.emptyText {
	// display: inline-block;
	font-size: 1.6rem;
	font-weight: 500;
	color: var(--black-2);
	text-align: center;
	margin: 0 auto;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	background-color: var(--gray-2);
	width: max-content;
	height: auto;
}

.listItem {
     padding: 8px,
	 
  }

.listItemTextChi {
	@include smallDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width:300px;
	}
	
 }

 .listItemText {
	@include smallover850LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include exsmallunder700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include exsmallover700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include smallunder700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include smallover700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	
 }

.total {
    font-weight: 700;
	@include smallover850LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include exsmallunder700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include exsmallover700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include smallunder700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
	@include smallover700LandscapeDeviceSize {
		word-wrap: break-word;
		font-weight: 700;
		width: 300px;
	}
  }

.boxLanding {

	@include exlargeover500LandscapeDeviceSize {
		height: 110vh;
		
	}
	@include exlargeover900LandscapeDeviceSize {
		height: 65vh;
		
	}
	@include exlargeover1200LandscapeDeviceSize {
		height: 30vh;
		
	}
	@include exlargeunder600LandscapeDeviceSize {
		height: 150vh;
		
	}

	@include largeover500LandscapeDeviceSize {
		height: 130vh;
		
	}
	@include largeover900LandscapeDeviceSize {
		height: 60vh;
		
	}
	@include largeover1100LandscapeDeviceSize {
		height: 40vh;
		
	}
	@include largeunder500LandscapeDeviceSize {
		height: 220vh;
		
	}
	@include exsmallunder700LandscapeDeviceSize {
		height: 240vh;
		
	}
	@include exsmallover700LandscapeDeviceSize {
		height: 170vh;
		
	}
	@include smallunder700LandscapeDeviceSize {
		height: 180vh;
		
	}
	@include smallover700LandscapeDeviceSize {
		height: 130vh;
		
	}
	@include smallover850LandscapeDeviceSize {
		height: 110vh;
		
	}
	@include mediumunder500LandscapeDeviceSize {
		height: 250vh;
		
	}
	@include mediumover700LandscapeDeviceSize {
		height: 130vh;
		
	}
	@include mediumover500LandscapeDeviceSize {
		height: 150vh;
		
	}
}

.boxHome {
    height: 73vh;
	@include smallDeviceSize {
		height: 5vh;
		
	}
	@include mediumDeviceSize {
		height: 70vh;
		
	}
}

.boxStepForm {
    // height: 35vh;
	// @include smallDeviceSize {
	// 	height: 75vh;
		
	// }
	@include exlargeover500LandscapeDeviceSize {
		height: 40vh;
		
	}
	@include exlargeover900LandscapeDeviceSize {
		height: 30vh;
		
	}
	@include exlargeover1200LandscapeDeviceSize {
		height: 10vh;
		
	}
	@include exlargeunder600LandscapeDeviceSize {
		height: 80vh;
		
	}

	@include largeover500LandscapeDeviceSize {
		height: 40vh;
		
	}
	@include largeover900LandscapeDeviceSize {
		height: 20vh;
		
	}
	@include largeover1100LandscapeDeviceSize {
		height: 10vh;
		
	}
	@include largeunder500LandscapeDeviceSize {
		height: 110vh;
		
	}
	@include largeunder400LandscapeDeviceSize {
		height: 120vh;
		
	}
	@include exsmallunder700LandscapeDeviceSize {
		height: 110vh;
		
	}
	@include exsmallover700LandscapeDeviceSize {
		height: 100vh;
		
	}
	@include smallunder700LandscapeDeviceSize {
		height: 100vh;
		
	}
	@include smallover700LandscapeDeviceSize {
		height: 80vh;
		
	}
	@include smallover850LandscapeDeviceSize {
		height: 60vh;
		
	}
	@include mediumunder500LandscapeDeviceSize {
		height: 140vh;
		
	}
	@include mediumover700LandscapeDeviceSize {
		height: 100vh;
		
	}
	@include mediumover500LandscapeDeviceSize {
		height: 100vh;
		
	}
	@include mediumover700LandscapeDeviceSize {
		height: 80vh;
		
	}
}

.boxStepForm2 {
    // height: 65vh;
	// @include smallDeviceSize {
	// 	height: 50vh;
		
	// }
	@include exlargeover500LandscapeDeviceSize {
		height: 30vh;
		
	}
	@include exlargeunder600LandscapeDeviceSize {
		height: 70vh;
		
	}
	@include exlargeover1200LandscapeDeviceSize {
		height: 20vh;
		
	}

	@include largeover500LandscapeDeviceSize {
		height: 10vh;
		
	}
	@include largeunder500LandscapeDeviceSize {
		height: 120vh;
		
	}
	@include exsmallunder700LandscapeDeviceSize {
		height: 70vh;
		
	}
	@include exsmallover700LandscapeDeviceSize {
		height: 70vh;
		
	}
	@include smallunder700LandscapeDeviceSize {
		height: 80vh;
		
	}
	@include smallover700LandscapeDeviceSize {
		height: 50vh;
		
	}
	@include mediumunder500LandscapeDeviceSize {
		height: 140vh;
		
	}
	@include mediumover700LandscapeDeviceSize {
		height: 70vh;
		
	}
	@include mediumover500LandscapeDeviceSize {
		height: 70vh;
		
	}
}

.title {
     margin-Top: 8px;
    font-Weight: 700,
  }

  .stepper {
     padding: 8px,
  }
  .buttons {
    display: 'flex';
    justify-Content: 'flex-end';
  }
  .button {
     margin-Top: 8px;
     margin-Left: 8px;
  }

  .text {
	height: 500px;
	overflow: auto;
	background: rgb(255, 255, 255);
	padding: 1rem;
	border-radius: 1rem;
	margin-bottom: 1rem;
	border-width: 1rem;
	border-color: #dedfe1;
	border-style:solid;
  }

  .checkboxgroup {
	padding: 8px;

  }
  
  