*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
:root {
	--primaryPurple: #646ff0;
	--black-1: #646681;
	--black-2: #585858;
	--bg-1: #f8f8ff;
	--bg-2: #ecedf6;
	--bg-3: #cccdde;
	--gray-1: #eee;
	--gray-2: #dedfe1;
	--black: black;
	--white: white;
}
html {
	font-size: 10px;
}
body {
	font-family: 'Poppins', sans-serif;
	width: 100%;
	min-height: 100vh;
	background-color: var(--bg-1);
}
* {
	font-family: 'Poppins', sans-serif;
}
.container {
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
}
