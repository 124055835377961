@mixin smallDeviceSize {
	@media only screen and  (min-width: 360px) {
		@content;
	}
}

@mixin mediumDeviceSize {
	@media only screen and (min-width: 480px) {
		@content;
	}
}

@mixin largeDeviceSize {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin extrasmallDeviceSize {
	@media only screen and  (max-width: 360px) {
		@content;
	}
}

@mixin extralargeDeviceSize {
	@media only screen and (min-width: 1024px) {
		@content;
	}
}

@mixin exlargeover500LandscapeDeviceSize {
	@media only screen and (min-width: 1024px) and (min-height: 600px) {
		@content;
	}
}

@mixin exlargeover900LandscapeDeviceSize {
	@media only screen and (min-width: 1024px) and (min-height: 900px) {
		@content;
	}
}

@mixin exlargeover1200LandscapeDeviceSize {
	@media only screen and (min-width: 1024px) and (min-height: 1200px) {
		@content;
	}
}

@mixin exlargeunder600LandscapeDeviceSize {
	@media only screen and (min-width: 1024px) and (max-height: 600px) {
		@content;
	}
}

@mixin largeover500LandscapeDeviceSize {
	@media only screen and (min-width: 768px) and (max-width: 1023px) and (min-height: 500px) {
		@content;
	}
}

@mixin largeover900LandscapeDeviceSize {
	@media only screen and (min-width: 768px) and (max-width: 1023px) and (min-height: 900px) {
		@content;
	}
}

@mixin largeover1100LandscapeDeviceSize {
	@media only screen and (min-width: 768px) and (max-width: 1023px) and (min-height: 1100px) {
		@content;
	}
}

@mixin largeunder500LandscapeDeviceSize {
	@media only screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 500px) {
		@content;
	}
}

@mixin largeunder400LandscapeDeviceSize {
	@media only screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 400px) {
		@content;
	}
}

@mixin exsmallunder700LandscapeDeviceSize {
	@media only screen and (max-width: 360px) and (max-height: 700px) {
		@content;
	}
}
@mixin exsmallover700LandscapeDeviceSize {
	@media only screen and (max-width: 360px) and (min-height: 700px) {
		@content;
	}
}

@mixin smallunder700LandscapeDeviceSize {
	@media only screen and (min-width: 361px) and (max-width: 479px) and (max-height: 700px) {
		@content;
	}
}
@mixin smallover700LandscapeDeviceSize {
	@media only screen and (min-width: 361px) and (max-width: 479px) and (min-height: 700px) {
		@content;
	}
}
@mixin smallover850LandscapeDeviceSize {
	@media only screen and (min-width: 361px) and (max-width: 479px) and (min-height: 850px) {
		@content;
	}
}

@mixin mediumunder500LandscapeDeviceSize {
	@media only screen and (min-width: 480px) and (max-width: 767px) and (max-height: 500px) {
		@content;
	}
}

@mixin mediumover500LandscapeDeviceSize {
	@media only screen and (min-width: 480px) and (max-width: 767px) and (min-height: 500px) {
		@content;
	}
}

@mixin mediumover700LandscapeDeviceSize {
	@media only screen and (min-width: 480px) and (max-width: 767px) and (min-height: 700px) {
		@content;
	}
}